<div
  class="ion-activatable wrapper subscription-item-wrapper state-{{ data.size }}"
  [class.state-small]="isSmall"
  [class.with-link]="supportLink"
  [class.without-breakfast]="noBreakfast"
  (click)="onGoToDetail()"
>
  <div class="inner">
    <div class="inner-content">
      <div class="date-con">
        <div class="date">{{ data?.startDate | date: 'd MMMM' }} - {{ data?.endDate | date: 'd MMMM' }}</div>
      </div>

      <div class="title"><b>{{ peTypeNames[data.size] }}</b></div>
      <div class="description">
        <div class="item">{{ duration }}</div>
        <div class="item">{{ durationType }} &nbsp; <i>{{ data?.feedDaysCount }} {{ data?.feedDaysCount | pluralize: 'days' }}</i></div>
      </div>
      <div class="footer">
        <ng-container *ngIf="data.status !== SubscriptionStatusEnum.Unpaid; else unpaid">
          <ng-container *ngIf="data?.personalSchedule; else days">
            <div class="day-custom">Свое расписание</div>
          </ng-container>

          <ng-template #days>
            <div class="day-item" *ngFor="let item of deliveryDays">{{ item }}</div>
          </ng-template>
        </ng-container>

        <ng-template #unpaid>
          <div class="badge-unpaid">Не оплачено</div>
        </ng-template>

        <div class="time">{{ deliveryTime }}</div>
      </div>

      <div class="breakfast">
        <app-icon icon="breakfast" class="icon-break"></app-icon>
        Без завтраков и ужинов
      </div>
    </div>
  </div>

  <ion-ripple-effect></ion-ripple-effect>
</div>
