import {
  Component,
  AfterViewInit,
  Self,
  Output,
  EventEmitter,
  OnChanges,
  OnInit,
  ChangeDetectorRef,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
  forwardRef,
} from '@angular/core';
import { combineLatest, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import pointInPolygon from 'point-in-polygon';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DadataResp, DaDataSuggestion } from '@shared/models';
import { CoordinatesService, NgOnDestroyService, DadataService } from '@shared/services';
import { DeliveryZoneEnum } from '@shared/enums';

export enum AddressMessages {
  free = '<span class="state-address-included">Адрес входит в зону бесплатной доставки</span>',
  paid = '<span class="state-address-with-payment">Доплата за каждую доставку 250 <span class="ruble">₽</span>, ' +
    'счет пришлет менеджер</span>',
  noDelivery = '<span class="state-address-not-included">Адрес находится вне зоны доставки.<br>' +
    'Менеджер свяжется для подтверждения адреса</span>',
  withoutHouseNumber = 'Пожалуйста, укажите дом',
  invalidAddress = 'Выберите адрес из списка',
  empty = 'Это поле обязательно',
}

@Component({
  selector: 'app-address-input',
  templateUrl: 'address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  providers: [
    NgOnDestroyService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddressInputComponent),
    },
  ],
})
export class AddressInputComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() customMessage: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() inputType = 'text';
  @Input() placeholderFloat = false;
  @Input() customInputClass = '';
  @Input() set isReady(data) {
    if (data) {
      this.input?.nativeElement?.focus();
    }
  }

  @Output() addressSelected = new EventEmitter<{ value: string; suggestion: DaDataSuggestion }>();
  @Output() zoneTypeDetermined = new EventEmitter<DeliveryZoneEnum>();

  value: string;
  viewValue: string;
  errorMessages: { [key: string]: string } = {
    incorrect: 'Пожалуйста, укажите дом',
    required: 'Это поле обязательно',
    invalid: 'Выберите адрес из списка',
    short: 'Адресс слишком короткий',
  };
  suggestions: DaDataSuggestion[] = [];
  isSelectedFromSuggestion = true;
  isHouseSelected = true;
  errorMessage = '';

  private coordinatesFree: string[][][] = [];
  private coordinatesPaid: string[][][] = [];
  private zoneTypeDescription = {
    [DeliveryZoneEnum.free]: AddressMessages.free,
    [DeliveryZoneEnum.paid]: AddressMessages.paid,
    [DeliveryZoneEnum.noDelivery]: AddressMessages.noDelivery,
  };

  @ViewChild('input') input: ElementRef;
  @ViewChild('container') container: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef,
    @Self() private ngOnDestroy$: NgOnDestroyService,
    private coordinatesService: CoordinatesService,
    private dadataService: DadataService,
  ) {}

  ngOnInit(): void {
    this.initCoordinates();
  }

  ngAfterViewInit(): void {
    this.initDadataHandler();
  }

  private initDadataHandler(): void {
    fromEvent(this.input.nativeElement, 'input')
      .pipe(debounceTime(350), takeUntil(this.ngOnDestroy$))
      .subscribe((input: InputEvent) => {
        const currentValue = this.input?.nativeElement?.value;

        this.isSelectedFromSuggestion = false;
        this.isHouseSelected = false;
        this.setInnerValue(currentValue);

        if (!currentValue || currentValue.length < 3) {
          return;
        }

        this.getSuggestions();
      });
  }

  private initCoordinates(): void {
    combineLatest([this.coordinatesService.msk$, this.coordinatesService.mo$])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([msk, mo]: [string[][][], string[][][]]) => {
        this.coordinatesFree = msk;
        this.coordinatesPaid = mo;
      });
  }

  private getSuggestions(): void {
    this.dadataService
      .getSuggestions(this.value)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((resp: DadataResp) => {
        this.suggestions = resp.suggestions?.length > 5 ? resp.suggestions.slice(0, 5) : resp.suggestions;

        this.markSuggestionsParts(resp.suggestions, this.value);
        this.cdr.markForCheck();
      });
  }

  private markSuggestionsParts(suggestions: DaDataSuggestion[], query: string): void {
    if (!query) {
      return;
    }

    const ignoredWords = ['г', 'ул', 'пер', 'д', 'к', 'обл', 'пр-кт', 'шоссе', 'пл', 'сквер', 'б-р'];
    const queryWords = query.split(' ');

    const matchingParts = [];

    for (const suggestion of suggestions) {
      suggestion.markedValue = suggestion.value;

      for (const word of queryWords) {
        const re = new RegExp(word, 'ig');
        const match = suggestion.value.match(re)?.[0] ? suggestion.value.match(re)[0] : null;

        if (ignoredWords.includes(word) || !match) {
          continue;
        }

        matchingParts.push(match);
      }

      matchingParts.forEach(part => {
        suggestion.markedValue = suggestion.markedValue.replace(part, `<span class="mark">${part}</span>`);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isReady.currentValue && this.value) {
      this.getSuggestions();
    }
  }

  selectSuggestion(event: MouseEvent, suggestion: DaDataSuggestion): void {
    event.stopPropagation();
    event.preventDefault();

    if (!!suggestion.data.house) {
      this.isHouseSelected = true;
    }

    this.isSelectedFromSuggestion = true;

    const qc_geo = suggestion?.data?.qc_geo;
    if (Number(qc_geo) > 1) {
      this.setInnerValue(suggestion.value + ',', true, suggestion);
      this.getSuggestions();
    } else {
      this.setInnerValue(suggestion.value, true, suggestion);
    }

    this.calculateDeliveryCost(suggestion);
  }

  private setInnerValue(value: string, showUpdateViewValue = false, suggestion: DaDataSuggestion = null): void {
    this.value = value;
    this.errorMessage = this.getErrorMessage();

    if (showUpdateViewValue) {
      this.viewValue = value;
    }

    this.addressSelected.emit(
      this.errorMessage
        ? null
        : {
            value,
            suggestion,
          },
    );
  }

  private getErrorMessage(): string {
    if (!this.value) {
      return this.errorMessages.required;
    }

    if (this.value && this.value?.length < 3) {
      return this.errorMessages.short;
    }

    if (!this.isSelectedFromSuggestion) {
      return this.errorMessages.invalid;
    }

    if (!this.isHouseSelected) {
      return this.errorMessages.incorrect;
    }

    return '';
  }

  private calculateDeliveryCost(suggestion: DaDataSuggestion): void {
    if (this.errorMessage) {
      return;
    }

    this.dadataService
      .getAddressCoordinates(suggestion)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(coords => {
        const zoneType = this.getZoneType(coords);

        this.zoneTypeDetermined.emit(zoneType);
        this.customMessage = this.zoneTypeDescription[zoneType];
      });
  }

  private getZoneType(coords): DeliveryZoneEnum {
    const isInFreeZone = this.isInZone(coords, this.coordinatesFree);
    const isInPaidZone = !isInFreeZone && this.isInZone(coords, this.coordinatesPaid);

    if (isInFreeZone) {
      return DeliveryZoneEnum.free;
    }

    if (isInPaidZone) {
      return DeliveryZoneEnum.paid;
    }

    return DeliveryZoneEnum.noDelivery;
  }

  private isInZone(coords: [number, number], coordinates: string[][][]): boolean {
    return coordinates.some(polygon => pointInPolygon(coords, polygon));
  }

  // implementation of `ControlValueAccessor`
  writeValue(value: string): void {
    this.value = value;
    this.viewValue = value;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: () => void): void {}

  setDisabledState(disabled: boolean): void {}
}
