import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ClientSubscription, ClientSubscriptionDetails } from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ClientSubscriptionsApiService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<ClientSubscription[]> {
    return this.http.get<ClientSubscription[]>(environment.url.subscriptionPEList);
  }

  public getSubscriptionDetails(subscriptionGuid: string): Observable<ClientSubscriptionDetails> {
    return this.http.get<ClientSubscriptionDetails>(`${environment.url.subscription}/${subscriptionGuid}/customization-details`);
  }

  public getPaymentIdBySubscription(subscriptionGuid: string): Observable<{ paymentId: string }> {
    return this.http.post<{ paymentId: string }>(`${environment.url.subscription}/${subscriptionGuid}/unpaid-payment`, null);
  }
}
