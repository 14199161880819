import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { PipesModule } from './pipes';
import { components } from './components';
import { directives } from './directives';
import { NgSelectModule } from '@ng-select/ng-select';

const modules = [
  ReactiveFormsModule,
  FormsModule,
  RouterModule,
  IonicModule,
  PipesModule,
  CommonModule,
  NgxMaskDirective,
  NgxMaskPipe,
  NgSelectModule,
];

@NgModule({
  declarations: [...components, ...directives],
  imports: [...modules],
  exports: [...modules, ...components, ...directives],
  providers: [provideNgxMask()],
})
export class SharedModule {}
